import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { StartComponent } from './components/start/start.component';
import { DorfmeisterschaftComponent } from './components/dorfmeisterschaft/dorfmeisterschaft.component';
import { SportComponent } from './components/sport/sport.component';
import { ContactComponent } from './components/contact/contact.component';
import { AboutComponent } from './components/about/about.component';
import { BoardComponent } from './components/board/board.component';
import { AnfahrtComponent } from './components/Anfahrt/anfahrt.component';
import { ChronikComponent } from './components/chronic/chronic.component';
import { ImpressumComponent } from './components/impressum/impressum.component';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { TermineComponent } from './components/Termine/termine.component';


const routes: Routes = [
  { path: '', redirectTo: 'Start', pathMatch: 'full' },
  { path: 'Start', component: StartComponent },
  { path: 'Dorfmeisterschaft', component: DorfmeisterschaftComponent },
  { path: 'Sport', component: SportComponent },
  { path: 'Kontakt', component: ContactComponent },
  { path: 'ÜberUns', component: AboutComponent },
  { path: 'Vorstandschaft', component: BoardComponent },
  { path: 'Anfahrt', component: AnfahrtComponent },
  { path: 'Chronik', component: ChronikComponent },
  { path: 'Impressum', component: ImpressumComponent },
  { path: 'Termine', component: TermineComponent },
  { path: 'Datenschutz', component: PrivacyComponent }];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
