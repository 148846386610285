import { Component } from '@angular/core';

@Component({
  templateUrl: './impressum.component.html',
})
export class ImpressumComponent {

  constructor() {

    window.addEventListener('load', () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    });

  }

}
