import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { StartComponent } from './start/start.component';
import { DorfmeisterschaftComponent } from './dorfmeisterschaft/dorfmeisterschaft.component';
import { SportComponent } from './sport/sport.component';
import { ContactComponent } from './contact/contact.component';
import { AboutComponent } from './about/about.component';
import { BoardComponent} from './board/board.component';
import { AnfahrtComponent} from './Anfahrt/anfahrt.component';
import { ChronikComponent } from './chronic/chronic.component';
import { ImpressumComponent } from './impressum/impressum.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { TermineComponent } from './Termine/termine.component';

import { MDBBootstrapModule } from 'angular-bootstrap-md';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,

    MDBBootstrapModule.forRoot(),
  ],
  declarations: [
    StartComponent,
    DorfmeisterschaftComponent,
    SportComponent,
    ContactComponent,
    AboutComponent,
    BoardComponent,
    AnfahrtComponent,
    ChronikComponent,
    ImpressumComponent,
    PrivacyComponent,
    TermineComponent
  ],
})
export class ComponentsModule { }
