import {Component} from '@angular/core';

@Component({
  templateUrl: './sport.component.html',
})
export class SportComponent {

  fullImagePath1: string;
  fullImagePath2: string;
  fullImagePath3: string;
  fullImagePath4: string;
  path: string;
  altText: string;

  constructor() {

    this.fullImagePath1 = '/../assets/images/sport/SSV-Landsham.jpeg';
    this.fullImagePath2 = '/../assets/images/sport/aufstieg2.jpg';
    this.fullImagePath3 = '/../assets/images/sport/aufstieg3.jpg';
    this.fullImagePath4 = '/../assets/images/sport/aufstieg4.jpg';

    window.addEventListener('load', () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    });
    
  }

  show(path, alt) {
    this.path = path;
    this.altText = alt;
  }

}


