import {Component} from '@angular/core';

@Component({
  templateUrl: './chronic.component.html',
})
export class ChronikComponent {

  path: string;
  altText: string;
  fullImagePath1: string;

  constructor() {

    this.fullImagePath1 = '/../assets/images/start/schafkopfturnier.JPG';

    window.addEventListener('load', () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    });
  }

  show(path, alt) {
    this.path = path;
    this.altText = alt;
  }

}


