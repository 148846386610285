import { Component } from '@angular/core';

@Component({
  templateUrl: './privacy.component.html',
})
export class PrivacyComponent {

  constructor() {

    window.addEventListener('load', () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    });

  }

}
