import { Component, OnInit } from '@angular/core';
import {Meta} from '@angular/platform-browser';
import {NavigationEnd, Router} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {


  constructor(private meta: Meta, private router: Router) {
    this.meta.addTags([
      {name: 'description', content: 'Webseite des Stockschützenverein Landsham e.V.'},
      {name: 'keywords', content: 'Stockschützenverein Landsham, Stockschützenverein, Landsham, SSV, SSV Stockschützenverein'}
    ]);

    router.events.pipe().subscribe((e: any) => {
      if(e.navigationTrigger){
        const rowHeader: HTMLElement = document.querySelector(".row-header");
        if(e.url !== "/Start" && e.url !== "/"){
          rowHeader.style.display = "none";
        } else {
          rowHeader.style.display = "flex";
        }
      }
    });
  }

  ngOnInit() {
    if(location.hash !== "#/Start"){
      const rowHeader: HTMLElement = document.querySelector(".row-header");
      rowHeader.style.display = "none";
    }
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo({ top: 0, behavior: 'smooth' });
    });
    
  }

}
